import { cn } from '@/lib'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const labelVariants = cva(
  'leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      variant: {
        'dialog-title': 'text-2xl font-light font-neue-machina',
        'dialog-field': 'text-sm font-light font-circular-xx',
        'registration-field': 'text-xs font-light font-neue-machina',
        'registration-title': 'text-2xl font-light font-neue-machina',
        checkbox: 'text-xl font-light font-circular-xx',
      },
      error: { true: 'text-error-accent' },
    },
  },
)

type LabelProps = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
  VariantProps<typeof labelVariants> & { htmlFor: string }

export const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, error, variant, ...props }, ref) => (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants({ variant, error }), className)}
      {...props}
    />
  ),
)
Label.displayName = LabelPrimitive.Root.displayName

export const LabelAsterisk = () => <span className='ml-0.5 text-xs'>*</span>

export const CharacterCount = ({ length = 0, max }: { length?: number; max: number }) => (
  <span className='font-circular-xx text-xs font-light'>{`${length}/${max}`}</span>
)
