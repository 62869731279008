import { auth } from '@/app/_auth'
import { appProduct, AppProduct } from '@/app/app-product-signal'
import { EmergenceLogo } from '@/components/emergence-logo'
import {
  EmergenceIcon,
  HistoryIcon,
  HomeIcon,
  LogoutIcon,
  SettingsIcon,
  WorkspaceIcon,
} from '@/components/icons'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui'
import { Avatar } from '@/components/ui/avatar'
import { T, useTranslate } from '@/i18n'
import { GA_EVENTS } from '@/lib'
import { computed } from '@preact/signals'
import { Link, type LinkProps } from '@tanstack/react-router'
import type { TranslationKey } from '@tolgee/web'
import { cva } from 'class-variance-authority'
import { signOut } from 'firebase/auth'
import React from 'react'
import ReactGA from 'react-ga4'
import useScript from 'react-script-hook'

export function SideNavigation() {
  const { t } = useTranslate()

  useScript({
    src: import.meta.env.VITE_TERMLY_SCRIPT_SRC,
    checkForExisting: true,
    defer: true,
  })

  return (
    <article className='group/side-nav absolute z-50 flex h-full'>
      <img
        alt=''
        src='/public/img/side-nav-bg.jpg'
        width={12}
        className='h-min min-h-full min-w-3'
        loading='lazy'
      />
      <div className='bg-sidebar-links flex w-full min-w-0 max-w-[275px] flex-col items-center gap-2 px-4 transition-[min-width] group-hover/side-nav:min-w-[275px]'>
        <div className='w-full py-10'>
          <a
            rel='noreferrer'
            href={import.meta.env.VITE_EMERGENCE_HOMEPAGE}
            target='_blank'
            className={linkVariants({ variant: 'logo' })}
            data-gtmid='navbar-logo-link'
            title={t('navigation.logoTitle')}
          >
            <EmergenceLogo width={167} className={toggleVariants({ expanded: 'show' })} />
            <EmergenceIcon className={toggleVariants({ expanded: 'hide' })} />
          </a>
        </div>

        <nav className='flex w-full flex-col items-center gap-2'>
          {navLinks.value.map((props) => (
            <NavItem key={props.to} {...props} />
          ))}
        </nav>

        <footer className='mt-auto flex w-full flex-col gap-3 py-4'>
          <button
            tabIndex={0}
            title={t('account.userProfile.a11y')}
            className='group/profile -mx-1.5 box-content flex flex-col gap-1 rounded-lg p-1.5 outline-none outline outline-1 outline-offset-0 focus-within:bg-[rgba(255,255,255,.2)] focus-within:outline-white hover:bg-[rgba(255,255,255,.2)] hover:outline-white'
          >
            <div className='invisible flex max-h-0 w-full flex-col gap-1 overflow-clip transition-all group-focus-within/profile:visible group-focus-within/profile:max-h-80 group-hover/profile:visible group-hover/profile:max-h-80'>
              <NavItem
                to='/account'
                titleKey='navigation.account.title'
                a11yKey='navigation.account.a11y'
                icon={<SettingsIcon />}
              />
              <NavItem
                to='/'
                onClick={() => signOut(auth)}
                titleKey='navigation.logout.title'
                a11yKey='navigation.logout.a11y'
                icon={<LogoutIcon />}
              />
            </div>
            <div className='flex h-10 cursor-default items-center gap-4 overflow-clip px-3'>
              <Avatar size='sm' />
              <span
                className={toggleVariants({
                  expanded: 'show',
                  className: 'overflow-ellipsis font-neue-machina text-xs font-normal',
                })}
              >
                {auth.currentUser?.email ?? ''}
              </span>
            </div>
          </button>

          <div
            className={toggleVariants({
              expanded: 'hide',
              className: 'flex h-[70px] items-center justify-center text-xl text-[#827C8C]',
            })}
          >
            ...
          </div>

          <div
            className={toggleVariants({
              expanded: 'show',
              className: 'h-[70px] flex-col gap-2',
            })}
          >
            <FooterLink
              href={import.meta.env.VITE_PRIVACY_POLICY_URL}
              target='_blank'
              rel='noreferrer'
              title={t('navigation.privacyPolicy.a11y')}
            >
              <T keyName='navigation.privacyPolicy.title' />
            </FooterLink>
            <FooterLink
              href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
              target='_blank'
              rel='noreferrer'
              title={t('navigation.terms.a11y')}
            >
              <T keyName='navigation.terms.title' />
            </FooterLink>
            <FooterLink
              href='#'
              className='termly-display-preferences'
              title={t('navigation.cookie.a11y')}
            >
              <T keyName='navigation.cookie.title' />
            </FooterLink>
          </div>
        </footer>
      </div>
    </article>
  )
}

// 👇 nav links

type NavLinkProps = LinkProps & {
  icon: React.ReactNode
  titleKey: TranslationKey
  a11yKey: TranslationKey
  onClick?: () => void
}

const NAV_LINKS: Array<NavLinkProps & { disableModes: AppProduct[] }> = [
  {
    to: '/',
    titleKey: 'navigation.home.title',
    a11yKey: 'navigation.home.a11y',
    icon: <HomeIcon />,
    disableModes: [AppProduct.Easy],
    onClick: () => {
      ReactGA.event({
        action: GA_EVENTS.ACTION.BTN,
        category: GA_EVENTS.CATEGORY.NAV,
        label: 'navigate_home',
      })
    },
  },
  {
    to: '/workspaces',
    titleKey: 'navigation.workspace.title',
    a11yKey: 'navigation.workspace.a11y',
    icon: <WorkspaceIcon />,
    disableModes: [AppProduct.Easy],
    onClick: () => {
      ReactGA.event({
        action: GA_EVENTS.ACTION.BTN,
        category: GA_EVENTS.CATEGORY.NAV,
        label: 'navigate_workspace',
      })
    },
  },
  {
    to: '/history',
    titleKey: 'navigation.history.title',
    a11yKey: 'navigation.history.a11y',
    icon: <HistoryIcon />,
    disableModes: [AppProduct.Easy],
    onClick: () => {
      ReactGA.event({
        action: GA_EVENTS.ACTION.BTN,
        category: GA_EVENTS.CATEGORY.NAV,
        label: 'navigate_history',
      })
    },
  },
]

const navLinks = computed(() =>
  NAV_LINKS.filter(({ disableModes }) => !disableModes.includes(appProduct.value)).map(
    ({ disableModes, ...navItemProps }) => navItemProps,
  ),
)

// 👇 link components

const NavItem = ({ titleKey, a11yKey, icon, ...props }: NavLinkProps) => {
  const { t } = useTranslate()
  const title = t(titleKey)

  const Comp = props.to ? Link : 'button'

  return (
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <Comp
          tabIndex={0}
          className={linkVariants({ variant: 'nav-item' })}
          title={t(a11yKey)}
          {...props}
        >
          {icon}
          <span
            className={toggleVariants({
              expanded: 'show',
              className: 'relative top-[3px] whitespace-nowrap pl-2',
            })}
          >
            {title}
          </span>
        </Comp>
      </TooltipTrigger>
      <TooltipContent
        className={toggleVariants({ expanded: 'hide' })}
        side='right'
        align='center'
        sideOffset={8}
      >
        {title}
      </TooltipContent>
    </Tooltip>
  )
}

const FooterLink = ({
  children,
  className,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a className={linkVariants({ variant: 'footer', className })} {...props}>
    {children}
  </a>
)

// 👇 className variants

const toggleVariants = cva('', {
  variants: {
    expanded: {
      show: 'hidden group-hover/side-nav:flex',
      hide: 'group-hover/side-nav:hidden',
    },
  },
})

const linkVariants = cva('rounded flex items-start outline-none', {
  variants: {
    variant: {
      logo: 'h-[44px] w-full p-2.5 cursor-pointer',
      'nav-item':
        'p-2.5 h-10 font-normal cursor-pointer select-none font-neue-machina w-full text-sm hover:bg-nav-item-hover focus:bg-nav-item-hover text-primary [&.active]:bg-nav-item-active [&.active]:focus:bg-nav-item-active',
      footer:
        'rounded-sm whitespace-nowrap text-xs font-light text-primary underline cursor-pointer py-[1px] ring-focus focus:ring w-fit',
    },
  },
})
