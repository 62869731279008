import type { AnyObject, ValidationErrors } from 'final-form'

export function trimFormValues<TValues extends AnyObject>(values: TValues): TValues {
  const entries: [keyof TValues, any][] = Object.entries(values)

  return entries.reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: typeof value === 'string' ? value.trim() : value,
    }),
    {} as TValues,
  )
}

export function validateFormValues<TValues extends AnyObject>({
  initialValues,
  validate,
}: {
  initialValues: TValues
  validate: (values: TValues) => ValidationErrors
}): { values: TValues; errors?: ValidationErrors } {
  const values = trimFormValues(initialValues)
  const errors = validate(values)

  if (errors && Object.keys(errors).length > 0) {
    return { values, errors }
  }

  return { values }
}
