/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'

// Create Virtual Routes

const HistoryLazyImport = createFileRoute('/history')()
const AccountLazyImport = createFileRoute('/account')()
const IndexLazyImport = createFileRoute('/')()
const WorkspacesIndexLazyImport = createFileRoute('/workspaces/')()
const WorkspacesWorkspaceIdIndexLazyImport = createFileRoute(
  '/workspaces/$workspaceId/',
)()

// Create/Update Routes

const HistoryLazyRoute = HistoryLazyImport.update({
  id: '/history',
  path: '/history',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/history.lazy').then((d) => d.Route))

const AccountLazyRoute = AccountLazyImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/account.lazy').then((d) => d.Route))

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const WorkspacesIndexLazyRoute = WorkspacesIndexLazyImport.update({
  id: '/workspaces/',
  path: '/workspaces/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/workspaces/index.lazy').then((d) => d.Route),
)

const WorkspacesWorkspaceIdIndexLazyRoute =
  WorkspacesWorkspaceIdIndexLazyImport.update({
    id: '/workspaces/$workspaceId/',
    path: '/workspaces/$workspaceId/',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/workspaces/$workspaceId/index.lazy').then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountLazyImport
      parentRoute: typeof rootRoute
    }
    '/history': {
      id: '/history'
      path: '/history'
      fullPath: '/history'
      preLoaderRoute: typeof HistoryLazyImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/': {
      id: '/workspaces/'
      path: '/workspaces'
      fullPath: '/workspaces'
      preLoaderRoute: typeof WorkspacesIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/$workspaceId/': {
      id: '/workspaces/$workspaceId/'
      path: '/workspaces/$workspaceId'
      fullPath: '/workspaces/$workspaceId'
      preLoaderRoute: typeof WorkspacesWorkspaceIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/workspaces': typeof WorkspacesIndexLazyRoute
  '/workspaces/$workspaceId': typeof WorkspacesWorkspaceIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/workspaces': typeof WorkspacesIndexLazyRoute
  '/workspaces/$workspaceId': typeof WorkspacesWorkspaceIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/account': typeof AccountLazyRoute
  '/history': typeof HistoryLazyRoute
  '/workspaces/': typeof WorkspacesIndexLazyRoute
  '/workspaces/$workspaceId/': typeof WorkspacesWorkspaceIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/account'
    | '/history'
    | '/workspaces'
    | '/workspaces/$workspaceId'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '/account' | '/history' | '/workspaces' | '/workspaces/$workspaceId'
  id:
    | '__root__'
    | '/'
    | '/account'
    | '/history'
    | '/workspaces/'
    | '/workspaces/$workspaceId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AccountLazyRoute: typeof AccountLazyRoute
  HistoryLazyRoute: typeof HistoryLazyRoute
  WorkspacesIndexLazyRoute: typeof WorkspacesIndexLazyRoute
  WorkspacesWorkspaceIdIndexLazyRoute: typeof WorkspacesWorkspaceIdIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AccountLazyRoute: AccountLazyRoute,
  HistoryLazyRoute: HistoryLazyRoute,
  WorkspacesIndexLazyRoute: WorkspacesIndexLazyRoute,
  WorkspacesWorkspaceIdIndexLazyRoute: WorkspacesWorkspaceIdIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/account",
        "/history",
        "/workspaces/",
        "/workspaces/$workspaceId/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/account": {
      "filePath": "account.lazy.tsx"
    },
    "/history": {
      "filePath": "history.lazy.tsx"
    },
    "/workspaces/": {
      "filePath": "workspaces/index.lazy.tsx"
    },
    "/workspaces/$workspaceId/": {
      "filePath": "workspaces/$workspaceId/index.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
