import { AuthStep, useAuth } from '@/app/_auth'
import { AuthStepTemplate, useRegistrationForm, useSaveRegistrationForm } from '@/app/_auth/steps'
import type { ConsentForm } from '@/app/account/_components'
import { Button, Checkbox, Label, toast } from '@/components/ui'
import { T, useTranslate } from '@/i18n'
import { GA_EVENTS } from '@/lib'
import { Field, Form } from 'react-final-form'
import ReactGA from 'react-ga4'

export function RegisterConsentStep() {
  const { t } = useTranslate()
  const { setStep } = useAuth()
  const { orgValues, purposeValues, consentValues, setConsentValues } = useRegistrationForm()
  const { isPending, mutateAsync } = useSaveRegistrationForm()

  const submit = (values: ConsentForm) => {
    setConsentValues(values)

    if (!orgValues) {
      setStep(AuthStep.RegisterOrg)
      toast.error(t('auth.steps.preferences.errorToast'))
      return
    }

    if (!purposeValues) {
      setStep(AuthStep.RegisterPurpose)
      toast.error(t('auth.steps.preferences.errorToast'))
      return
    }

    ReactGA.event({
      action: GA_EVENTS.ACTION.BTN,
      category: GA_EVENTS.CATEGORY.ACC,
      label: 'submit_consent_settings_registration_form',
    })

    mutateAsync({ ...values, ...orgValues, ...purposeValues })
      .then(() => setStep(AuthStep.GetEmergenceUser))
      .catch((e) => {
        console.error(e)
        toast.error('Failed to submit registration')
      })
  }

  const back = (values: ConsentForm) => {
    setConsentValues(values)
    setStep(AuthStep.RegisterPurpose)
  }

  return (
    <AuthStepTemplate>
      <Form<ConsentForm>
        onSubmit={submit}
        initialValues={consentValues ?? { allowMarketing: true, allowData: true }}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className='flex flex-col gap-8'>
            <AuthStepTemplate.Header>
              <AuthStepTemplate.H2 keyName='auth.steps.preferences.title' />
            </AuthStepTemplate.Header>
            <div className='flex w-full flex-col gap-3'>
              <Field name='allowData'>
                {({ input }) => (
                  <div className='flex gap-3'>
                    <Checkbox
                      autoFocus
                      className='mt-2'
                      checked={input.value}
                      onCheckedChange={input.onChange}
                      name={input.name}
                    />
                    <Label htmlFor={input.name} variant='checkbox'>
                      <T keyName='auth.steps.preferences.allowData' />
                    </Label>
                  </div>
                )}
              </Field>
              <Field name='allowMarketing'>
                {({ input }) => (
                  <div className='flex gap-3'>
                    <Checkbox
                      className='mt-2'
                      checked={input.value}
                      onCheckedChange={input.onChange}
                      name={input.name}
                    />
                    <Label htmlFor={input.name} variant='checkbox'>
                      <T keyName='auth.steps.preferences.allowFeedbackRequests' />
                    </Label>
                  </div>
                )}
              </Field>
              <div className='mx-auto max-w-xs py-5 text-center text-sm font-medium'>
                <T
                  keyName='auth.steps.preferences.terms'
                  params={{
                    t: (
                      <a
                        className='text-link underline'
                        href={import.meta.env.VITE_TERMS_OF_SERVICE_URL}
                        target='_blank'
                        title={t('navigation.privacyPolicy.a11y')}
                      />
                    ),
                    p: (
                      <a
                        className='text-link underline'
                        href={import.meta.env.VITE_PRIVACY_POLICY_URL}
                        target='_blank'
                        title={t('navigation.terms.a11y')}
                      />
                    ),
                  }}
                />
              </div>
              <AuthStepTemplate.ButtonRow>
                <Button
                  onClick={() => back(values)}
                  variant='square-outline'
                  className='w-full'
                  size='sm'
                  disabled={isPending}
                >
                  <T keyName='auth.steps.common.buttons.back' />
                </Button>
                <Button
                  loading={isPending}
                  variant='square'
                  size='sm'
                  className='w-full'
                  type='submit'
                >
                  <T keyName='auth.steps.common.buttons.createAccount' />
                </Button>
              </AuthStepTemplate.ButtonRow>
            </div>
          </form>
        )}
      </Form>
      <AuthStepTemplate.Footer />
    </AuthStepTemplate>
  )
}
