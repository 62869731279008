import React from 'react'
import TextArea from 'react-textarea-autosize'

type TextAreaPromptProps = {
  placeholder: string
  error?: Error
  errorMessage?: string
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
}

const TextAreaPrompt = React.forwardRef<HTMLTextAreaElement, TextAreaPromptProps>(
  ({ error, errorMessage, ...rest }, ref) => {
    return (
      <div className='flex w-full flex-col gap-0.5 font-neue-machina font-light'>
        <TextArea
          ref={ref}
          minRows={1}
          maxLength={1200}
          className='w-full resize-none bg-content py-1.5 text-base text-primary outline-none placeholder:text-grey-900'
          {...rest}
        />
        {error && errorMessage && (
          <span className='mt-0.5 text-sm text-error-accent'>{errorMessage}</span>
        )}
      </div>
    )
  },
)
TextAreaPrompt.displayName = 'TextAreaPrompt'

export { TextAreaPrompt }
