import { cn } from '@/lib/utils'
import {
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
} from 'react'

interface TableProps extends HTMLAttributes<HTMLTableElement> {
  parentClassName?: string
}

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, parentClassName, children, ...props }, ref) => {
    const parentRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const handleScroll = () => {
        const parent = parentRef.current
        if (parent) {
          const thead = parent.querySelector('thead')
          if (thead) {
            thead.classList.toggle('shadow-xl', parent.scrollTop > 0)
          }
        }
      }
      if (parentRef.current) {
        parentRef.current.addEventListener('scroll', handleScroll)
      }

      return () => {
        if (parentRef.current) {
          parentRef.current.removeEventListener('scroll', handleScroll)
        }
      }
    }, [])

    return (
      <div
        ref={parentRef}
        className={cn('relative w-full overflow-auto overflow-x-scroll', parentClassName)}
      >
        <table ref={ref} className={cn('w-full', className)} {...props}>
          {children}
        </table>
      </div>
    )
  },
)

Table.displayName = 'Table'

const TableHeader = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={cn('sticky top-0 h-12 border-b bg-white', className)} {...props} />
  ),
)
TableHeader.displayName = 'TableHeader'

const TableBody = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tbody ref={ref} className={cn('', className)} {...props} />,
)
TableBody.displayName = 'TableBody'

const TableFooter = forwardRef<HTMLTableSectionElement, HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <tfoot ref={ref} className={cn('', className)} {...props} />,
)
TableFooter.displayName = 'TableFooter'

const TableRow = forwardRef<HTMLTableRowElement, HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => <tr ref={ref} className={cn('', className)} {...props} />,
)
TableRow.displayName = 'TableRow'

const TableHead = forwardRef<HTMLTableCellElement, ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={cn('px-6 py-3 text-left font-neue-machina text-sm font-normal', className)}
      {...props}
    />
  ),
)
TableHead.displayName = 'TableHead'

const TableCell = forwardRef<HTMLTableCellElement, TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn('min-h-16 border-b px-6 py-3 font-light text-primary', className)}
      {...props}
    />
  ),
)
TableCell.displayName = 'TableCell'

const TableCaption = forwardRef<HTMLTableCaptionElement, HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={cn('mt-4 text-sm text-neutral-500', className)} {...props} />
  ),
)
TableCaption.displayName = 'TableCaption'

export { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow }
