import { SideNavigation } from '@/app/_common'
import { AppProduct, appProduct, DEFAULT_APP_PRODUCT } from '@/app/app-product-signal'
import { Personas } from '@/app/home/_utils/types'
import { NotFoundPage } from '@/app/not-found'
import {
  createRootRoute,
  notFound,
  Outlet,
  retainSearchParams,
  useNavigate,
  useSearch,
} from '@tanstack/react-router'
import React from 'react'

type SearchParams = {
  persona?: Personas
  product?: AppProduct
  prompt?: string
  title?: string
}

export const Route = createRootRoute({
  notFoundComponent: NotFoundPage,
  validateSearch: () => ({}) as SearchParams,
  search: {
    middlewares: [retainSearchParams(['product'])],
  },
  component: RootRoute,
})

function RootRoute() {
  const { product = DEFAULT_APP_PRODUCT } = useSearch({ strict: false })
  const navigate = useNavigate()

  React.useEffect(() => {
    const validAppProduct = isValidAppProduct(product)
    appProduct.value = validAppProduct ? product : DEFAULT_APP_PRODUCT

    if (!validAppProduct) {
      notFound({ throw: true })
    }

    if (appProduct.value === AppProduct.Easy) {
      navigate({ to: '/account', search: { product: appProduct.value } })
    }
  })

  return (
    <>
      <SideNavigation />
      <Outlet />
    </>
  )
}

const isValidAppProduct = (str: string): str is AppProduct => {
  if (Object.values(AppProduct).includes(str as AppProduct)) {
    switch (str) {
      case AppProduct.Easy: {
        return import.meta.env.VITE_EASY_PRODUCT_ENABLED === 'true'
      }
      case AppProduct.Orchestrator: {
        return import.meta.env.VITE_ORCHESTRATOR_PRODUCT_ENABLED === 'true'
      }
      default:
        return true
    }
  }
  return false
}
