import { AlertIcon } from '@/components/icons'
import { Button } from '@/components/ui'
import { T } from '@/i18n'
import { Link } from '@tanstack/react-router'
import { PageTemplate } from './_common'
import { DEFAULT_APP_PRODUCT } from './app-product-signal'

export const NotFoundPage = () => (
  <PageTemplate noPadding className='h-full items-center justify-center gap-8'>
    <AlertIcon />
    <PageTemplate.H1 className='max-w-[485px] text-center font-light text-error-accent'>
      <T keyName='notFound.title' />
    </PageTemplate.H1>
    <Button size='lg' asChild className='font-medium uppercase'>
      <Link to='/' search={{ product: DEFAULT_APP_PRODUCT }}>
        <T keyName='notFound.mainCTA' />
      </Link>
    </Button>
  </PageTemplate>
)
